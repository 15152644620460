<template>
  <section>
    <h6 class="text-h5 my-4">{{ $t("products") }}</h6>

    <v-row
      v-for="{ id, name, importe, qty, image, items, details } in products"
      :key="id"
      class="my-3 rounded-lg startup-list-card"
      justify="center"
    >
      <v-col class="pa-0">
        <base-img
          :aspect-ratio="1"
          :src="image || require('@/assets/no-image.png')"
          class="rounded-lg"
          max-width="12rem"
        />
      </v-col>
      <v-col cols="9" class="pl-5 pt-0">
        <h6 class="text-justify text-h6 mb-2">{{ name }}</h6>

        <section class="text-left" v-if="items.length > 0">
          <p v-for="item in items" :key="item.id">
            - {{ item.name }} * {{ item.qty }}
          </p>
        </section>

        <p class="my-3 text-left">{{ details }}</p>

        <div class="d-flex justify-space-around font-weight-bold">
          <p
            class="text-justify text-h6 font-weight-normal grey--text mt-3 mb-0"
          >
            {{ importe && formatPrice(importe) }}
          </p>
          <p
            class="text-justify text-h6 font-weight-normal grey--text mt-3 mb-0"
          >
            Qty: {{ qty }}
          </p>
        </div>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import { formatPrice } from "@/helpers";

export default {
  name: "OrderDetailsSection",
  props: {
    products: { type: Array, required: true },
  },
  methods: {
    formatPrice,
  },
};
</script>
