var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('h6',{staticClass:"text-h5 my-4"},[_vm._v(_vm._s(_vm.$t("products")))]),_vm._l((_vm.products),function(ref){
var id = ref.id;
var name = ref.name;
var importe = ref.importe;
var qty = ref.qty;
var image = ref.image;
var items = ref.items;
var details = ref.details;
return _c('v-row',{key:id,staticClass:"my-3 rounded-lg startup-list-card",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-0"},[_c('base-img',{staticClass:"rounded-lg",attrs:{"aspect-ratio":1,"src":image || require('@/assets/no-image.png'),"max-width":"12rem"}})],1),_c('v-col',{staticClass:"pl-5 pt-0",attrs:{"cols":"9"}},[_c('h6',{staticClass:"text-justify text-h6 mb-2"},[_vm._v(_vm._s(name))]),(items.length > 0)?_c('section',{staticClass:"text-left"},_vm._l((items),function(item){return _c('p',{key:item.id},[_vm._v(" - "+_vm._s(item.name)+" * "+_vm._s(item.qty)+" ")])}),0):_vm._e(),_c('p',{staticClass:"my-3 text-left"},[_vm._v(_vm._s(details))]),_c('div',{staticClass:"d-flex justify-space-around font-weight-bold"},[_c('p',{staticClass:"text-justify text-h6 font-weight-normal grey--text mt-3 mb-0"},[_vm._v(" "+_vm._s(importe && _vm.formatPrice(importe))+" ")]),_c('p',{staticClass:"text-justify text-h6 font-weight-normal grey--text mt-3 mb-0"},[_vm._v(" Qty: "+_vm._s(qty)+" ")])])])],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }